import React, { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import ErrorAlert from '../../../components/confirm-alert/error-alert';
import Dropzone from 'react-dropzone';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import LoaderComponent from '../../../components/loader/Loader';

const UploadModal = ({ isOpen, onConfirmUploadedFiles, onRequestClose, currentRowFiles: initialData}) => {
  
  const [files, setFiles] = useState(initialData || []);
  const [file, setFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showRecognitionForm, setShowRecognitionForm] = useState(false);
  const [recognitionProgress, setRecognitionProgress] = useState({ state: false, value: 0 });
  const inputFileRef = useRef(null);

  

  const pickUpFile = () => {
    inputFileRef.current.click();
  }

  const onFileInputChange = (acceptedFiles) => {
    const selectedFiles = Array.from(acceptedFiles);
    selectedFiles.forEach(file => {
      if (!file.type.includes("pdf") && !file.type.includes("png") && !file.type.includes("jpeg")) {
        setErrorMsg("Format de relevé incorrect, les types requisent sont PDF PNG ou JPEG")
      } else {
        setErrorMsg(null)
        readFile(file);
      }
    });
  }

  const readFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      //console.log("file",file)
      //console.log("reader",reader)
      setFiles(prevFiles => [...prevFiles, { file, fileConverted: reader.result }]);
      setShowRecognitionForm(true);
    };
  }



  const deleteFromList = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  }

  useEffect(() => {
    if (initialData) {
      //console.log(initialData)
      setFiles(initialData);
    }
  }, [initialData]);

  // const preloadImage = (src) => {
  //   return new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.src = src;
  //     img.onload = resolve;
  //     img.onerror = reject;
  //   });
  // };
  
  // useEffect(() => {
  //   files.forEach((data) => {
  //     if (data.file.type.startsWith('image/')) {
  //       preloadImage(data.fileConverted)
  //         .then(() => {
  //           console.log(`${data.file.name} loaded`);
  //         })
  //         .catch((error) => {
  //           console.error(`Failed to load ${data.file.name}: `, error);
  //         });
  //     }
  //   });
  // }, [files]);

  return (
    <Modal
      title={"Importation nouveau relevés"}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={true}
      isFullScreenBankStatments={true}
      footer={
        <>
          <button className='m-auto'
          style={{opacity: ((!files || files.length == 0) && (!showRecognitionForm))? .5:1}}
            disabled={(!files || files.length == 0) && (!showRecognitionForm)}
           onClick={() => onConfirmUploadedFiles(files)}>Valider</button>
          <button className='m-auto' onClick={() => onRequestClose(false)}>Fermer</button>
        </>
      }
    >
      <div className="upload-statements-content">
        <div className="page">
          <div className="row">
            <div className="col-sm-5 left-side">
              <div className="actions">
                <div className="imported-files">
                  <h2>Fichiers importés</h2>
                  <ul>
                    {files?.map(file => {
                      return <li><i class={file["file"]?.type === "application/pdf" ? "fa fa-file-pdf text-success mr-2":"fa fa-file-image text-success mr-2"} aria-hidden="true" style={{fontSize: "18px"}}></i>{file["file"]?.name}</li>
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-7 viewer" id="viewer">
              <h2>Importation Fichier</h2>
              {errorMsg &&
                <div className="alert alert-danger mt-2 text-center">
                  {errorMsg}
                </div>
              }
              <div className="pickup-file d-flex">
                <label>Chemin</label>
                <div className="file-path">
                  <input value={file?.name ? file.name : ''} type="text" name="file-path" className="form-control" readOnly={true} />
                </div>
                <button onClick={pickUpFile} className="btn btn-light">
                  <svg width="31" height="5" viewBox="0 0 31 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2.5" cy="2.5" r="2.5" fill="#828282" />
                    <circle cx="15.5" cy="2.5" r="2.5" fill="#828282" />
                    <circle cx="28.5" cy="2.5" r="2.5" fill="#828282" />
                  </svg>
                </button>
              </div>

              <Dropzone onDrop={onFileInputChange}>
                {({ getRootProps, getInputProps }) => (
                  <div className={`drop-file ${recognitionProgress?.state ? 'd-none' : ''}`} {...getRootProps({onClick: event => pickUpFile()})}>
                    <input {...getInputProps()} ref={inputFileRef} type="file" accept="application/pdf, image/png, image/jpeg" name="file-input" id="file-input" />
                    <svg width="181" height="161" viewBox="0 0 181 161" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M90.5002 77.5469V155.858C90.5002 158.633 88.2726 160.882 85.5247 160.882C82.7769 160.882 80.5493 158.633 80.5493 155.858V77.5469L59.19 99.1166C57.247 101.079 54.0966 101.079 52.1536 99.1166C50.2105 97.1544 50.2105 93.973 52.1536 92.0108L82.0065 61.8638C83.9496 59.9016 87.0999 59.9016 89.043 61.8638L118.896 92.0108C120.839 93.973 120.839 97.1544 118.896 99.1166C116.953 101.079 113.803 101.079 111.859 99.1166L90.5002 77.5469ZM127.756 30.2451H135.28C160.011 30.2451 180.059 50.491 180.059 75.4657C180.059 100.44 160.011 120.686 135.28 120.686C132.532 120.686 130.304 118.437 130.304 115.662C130.304 112.887 132.532 110.637 135.28 110.637C154.515 110.637 170.108 94.8904 170.108 75.4657C170.108 56.0409 154.515 40.2941 135.28 40.2941H130.031C130.211 41.9438 130.304 43.6203 130.304 45.3186C130.304 48.0936 128.077 50.3431 125.329 50.3431C122.581 50.3431 120.353 48.0936 120.353 45.3186C120.353 25.8939 104.76 10.147 85.5247 10.147H80.5493C58.5662 10.147 40.7453 28.1434 40.7453 50.3431V55.3676C40.7453 58.1426 38.5177 60.3921 35.7698 60.3921C22.0304 60.3921 10.8924 71.6399 10.8924 85.5147C10.8924 99.3895 22.0304 110.637 35.7698 110.637C38.5177 110.637 40.7453 112.887 40.7453 115.662C40.7453 118.437 38.5177 120.686 35.7698 120.686C16.5346 120.686 0.941406 104.939 0.941406 85.5147C0.941406 67.7959 13.916 53.1373 30.7943 50.6993C30.7943 22.5935 53.0704 0.0980225 80.5493 0.0980225H85.5247C105.022 0.0980225 121.609 12.6815 127.756 30.2451Z" fill="#F66031" />
                    </svg>
                  </div>
                )}
              </Dropzone>


              {files && files.length > 0 && files.map((data, index) => (
                <div className='preview-control' key={index}>
                  <div className={data.file.type === 'application/pdf' ? "preview custom-preview":"preview custom-preview-img"}>
                  {data.file.type === 'application/pdf' ? (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                      <div>
                        <Viewer fileUrl={data.fileConverted} />
                      </div>
                    </Worker>
                    ):(
                      <img src={data.fileConverted} alt={data.file.name} className="image-preview" />
                    )}
                  </div>
                  <h3>Aperçu {data['file'].name}</h3>
                  <p></p>
                  <p onClick={() => deleteFromList(index)} className="delete-btn">Supprimer</p>
                  <hr></hr>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default withTranslation()(UploadModal);
